<template>
  <div>
    <!-- <CourseItem :hots="courses" /> -->
    <GridItem :hots="courses" />
    <van-empty v-show="isEmpty" />
  </div>
</template>

<script>
import GridItem from '@/components/Grid-item'
import { getMyBuyOrder, myCollections } from '@/api/user'
export default {
  data () {
    return {
      courses: [], // 课程列表数据
      pages: {
        cateId: null,
        pagenum: 1,
        pagesize: 15,
        total_pages: null,
        total: null
      },
      loading: false, // 上拉加载更多的 loading 状态
      finished: false, // 是否加载结束
      error: false, // 是否加载失败
      type: '' // 加载数据类型
    }
  },
  components: {
    GridItem
  },
  mounted () {
    this.type = this.$route.query.type
    document.title = 'ءتىزىم'
    this.onLoad()
  },
  computed: {
    isEmpty () {
      return this.finished && !this.pages.total && !this.courses.length
    }
  },
  methods: {
    // 监听底部事件
    async onLoad () {
      try {
        switch (this.type) {
          case 'buy':
            this.getMyBuyOrder()
            break
          case 'shoucang':
            this.myCollections()
            break
          default:
            break
        }
      } catch (err) {
        console.log(err)
        this.loading = false // 关闭 loading 效果
        this.error = true // 开启错误提示
      }
    },

    async getMyBuyOrder () {
      // 1. 请求获取数据
      const { data } = await getMyBuyOrder({
        ...this.pages
      })
      // 2. 把数据添加到 courses 数组中
      let results = data.data
      results = results.map(item => item.course)
      this.courses.push(...results)
      this.pages.total = data.meta.total
      // 3. 设置本次加载中 loading 状态结束
      this.loading = false
      const { pagenum, pagesize } = this.pages
      // 4. 判断数据是否加载结束
      if (pagenum * pagesize >= data.meta.total) {
        // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
        this.finished = true
      } else {
        // 更新获取下一页数据的时间戳
        this.pages.pagenum = pagenum + 1
      }
    },

    async myCollections () {
      // 1. 请求获取数据
      const { data } = await myCollections({
        ...this.pages
      })
      // 2. 把数据添加到 courses 数组中
      let results = data.data
      results = results.map(item => item.course)
      this.courses.push(...results)
      this.pages.total = data.meta.total
      // 3. 设置本次加载中 loading 状态结束
      this.loading = false
      const { pagenum, pagesize } = this.pages
      // 4. 判断数据是否加载结束
      if (pagenum * pagesize >= data.meta.total) {
        // 没有数据了，设置加载状态结束，不再触发上拉加载更多了
        this.finished = true
      } else {
        // 更新获取下一页数据的时间戳
        this.pages.pagenum = pagenum + 1
      }
    }
  }
}
</script>

<style>
</style>
